import React from 'react';
import { navigate } from '@reach/router';
import { Typography, makeStyles, GridList, GridListTile, GridListTileBar, Chip } from '@material-ui/core';
import _ from 'lodash';

import { useDnnContext } from '../useDnnContext';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  chip: {
    marginRight: 5,
  },
  container: {
    width: 800,
  },
  tile: {
    cursor: 'pointer',
  }
}));

function Home() {
  const classes = useStyles();
  const dnnContext = useDnnContext();

  return (
    <div className={classes.root}>
      <Typography variant="body1">
        I am fairly new to machine learning, but I have been enjoying it. So I decided to share what I have
        been doing.
      </Typography>
      <div className={classes.container}>
        <GridList cellHeight={300} cellSpacing={1} className={classes.c2}>
          {_.map(dnnContext, dnn => {
            return (

              <GridListTile
                key={dnn.key}
                onClick={() => navigate(dnn.path)}
                className={classes.tile}
                >
                <img
                  src={dnn.image}
                  alt="DNN example image"
                  />
                <GridListTileBar
                  title={dnn.title}
                  actionIcon={<Chip label={dnn.label} size="small" className={classes.chip} />}
                  />
              </GridListTile>
            );
          })}
        </GridList>
      </div>
    </div>
  );
}

export default Home;
