import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Gallery from 'react-grid-gallery';
import { navigate } from '@reach/router';
import { Breadcrumbs, Button, Grid, Typography } from '@material-ui/core';
import BookIcon from '@material-ui/icons/Book';
import Markdown from 'react-markdown'
import _ from 'lodash';

function GanHome({ modelPath, mlType }) {
  const [data, setData] = useState([]);
  const [mdData, setMdData] = useState([]);
  const baseApiUrl = useMemo(
    () => { return `https://ml.spidermo.net/${mlType}-${modelPath}`; },
    [modelPath, mlType]
  );

  useEffect(() => {
    const fetchData = async () => {
      fetchMarkdownData();
      fetchImageData();
    };

    fetchData();
  }, [baseApiUrl]);

  async function fetchImageData() {
    const response = await axios.get(`${baseApiUrl}/output/index.json`);
    const responseData = _.map(response.data, (groupData, _groupId) => ({
      src: `${baseApiUrl}/output/${groupData.src}`,
      thumbnail: `${baseApiUrl}/output/thumbs/${groupData.src}`,
      thumbnailCaption: `${groupData.begin} - ${groupData.end} (${groupData.count})`,
      groupId: groupData.begin,
      thumbnailWidth: 240,
      thumbnailHeight: 180,
    }));
    setData(responseData);
  }

  async function fetchMarkdownData() {
    const response = await axios.get(`${baseApiUrl}/index.md`);
    const content = (response.status === 200) ? response.data : null;
    setMdData(content);
  }

  function openGroup() {
    navigate(`/${mlType}/${modelPath}/${this.props.item.groupId}`);
  }

  function openNotebook() {
    navigate(`/${mlType}/${modelPath}/notebook`);
  }

  return (
    <div>
      <Grid container justify="space-between" spacing={2}>
        <Grid item>
          <Breadcrumbs>
            <Typography variant="h6">{ _.chain(modelPath).words().map(_.capitalize).join(' ').value() }</Typography>
          </Breadcrumbs>
        </Grid>
        {/* <Grid item>
          <Button variant="outlined" onClick={openNotebook} startIcon={<BookIcon />}>Notebook</Button>
        </Grid> */}
      </Grid>
      <Grid container alignItems="center">
        <Grid item style={{ textAlign: "start" }}>
          <Markdown source={mdData}/>
        </Grid>
      </Grid>
      <Gallery images={data} onClickThumbnail={openGroup} enableImageSelection={false}/>
    </div>
  );
}

export default GanHome;
