import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Gallery from 'react-grid-gallery';
import { Link } from '@reach/router';
import { Breadcrumbs, Typography } from '@material-ui/core';
import _ from 'lodash';

function GanGroup({ groupId, modelPath, mlType }) {
  const [data, setData] = useState([]);
  const baseApiUrl = useMemo(
    () => { return `https://ml.spidermo.net/${mlType}-${modelPath}/output`; },
    [modelPath, mlType]
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${baseApiUrl}/${groupId}.json`);
      const responseData = _.map(response.data, image => ({
        src: `${baseApiUrl}/${image}`,
        thumbnail: `${baseApiUrl}/thumbs/${image}`,
        thumbnailCaption: image,
        thumbnailWidth: 240,
        thumbnailHeight: 180,
      }));
      setData(responseData);
    };

    fetchData();
  }, [groupId, baseApiUrl]);

  return (
    <div>
    <Breadcrumbs>
      <Link to=".." className="nav-link"><Typography variant="h6">{ _.chain(modelPath).words().map(_.capitalize).join(' ').value() }</Typography></Link>
      <Typography variant="h6">{ parseInt(groupId).toLocaleString() }</Typography>
    </Breadcrumbs>
      <Gallery images={data} enableImageSelection={false}/>
    </div>
  );
}

export default GanGroup;
