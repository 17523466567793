import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { Container, Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from '@reach/router';
import _ from 'lodash';

import { useDnnContext } from './useDnnContext';
import './App.css';

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const Layout = (props) => {
  const [navOpen, setNavOpen] = useState(false);
  const dnnContext = useDnnContext();

  return (
    <div>
      <ElevationScroll {...props} >
        <AppBar>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setNavOpen(true)}>
              <MenuIcon />
            </IconButton>
            <Link to="/" className="nav-title">
              <Typography variant="h6">
                Spidermo: Machine Learning
              </Typography>
            </Link>
            <img src="/logo192.png" alt="Spidermo: Machine Learning logo" className="nav-logo" />
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar/>
      <Drawer open={navOpen} onClick={() => setNavOpen(false)} onClose={() => setNavOpen(false)}>
        <List>
          <Link to="/" className="nav-link">
            <ListItem button key={'home'}>
              <ListItemText primary={'Home'} />
            </ListItem>
          </Link>
          {_.map(dnnContext, dnn => {
            return (
              <Link to={dnn.path} className="nav-link">
                <ListItem button key={dnn.key}>
                  <ListItemText primary={dnn.title} />
                </ListItem>
              </Link>);
          })}
        </List>
      </Drawer>
    </div>
  )
}

const AppLayout = (Component) => (props) => {

  return (
    <div className="App">
      <Layout />
      <Container>
        <Component { ...props } />
      </Container>
    </div>
  );
};

export default AppLayout;
