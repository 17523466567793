import React from 'react';
import { Router } from '@reach/router';

import GanHome from "./screens/GanHome";
import GanGroup from "./screens/GanGroup";
import Home from "./screens/Home";

import AppLayout from "./AppLayout";
import { useDnnContextProvider } from "./useDnnContext";

const GanHomeLayout = AppLayout(GanHome);
const GanGroupLayout = AppLayout(GanGroup);
const HomeLayout = AppLayout(Home);

function App() {
  const DnnContextProvider = useDnnContextProvider();

  return (
    <DnnContextProvider>
      <Router>
        <HomeLayout path="/"/>
        <GanHomeLayout path="gan/:modelPath" mlType="gan"/>
        <GanGroupLayout path="gan/:modelPath/:groupId" mlType="gan"/>
        <GanHomeLayout path="stylegan2/:modelPath" mlType="stylegan2"/>
        <GanGroupLayout path="stylegan2/:modelPath/:groupId" mlType="stylegan2"/>
      </Router>
    </DnnContextProvider>
  );
}

export default App;
