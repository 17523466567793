import React, { useEffect, useState } from 'react';

export const useFetchContext = (
  Ctx,
  request,
  initialData = null,
) => {
  const [data, setData] = useState(initialData);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(request);
        const json = await response.json();
        setData(json);
      } catch (err) {
        // handle error
      }
    };

    if (!data) getData();

  // currently only runs once. Add request and data as dependencies
  // in the array below if you'll be feeding it a stateful request.
  }, []);

  const CtxProvider = ({ children }) => {
    return <Ctx.Provider value={data}>{children}</Ctx.Provider>;
  };

  return CtxProvider;
};
